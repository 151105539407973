import { Component, Input, OnInit } from '@angular/core';

import { LoadingController, ModalController } from '@ionic/angular';

@Component({
    selector: 'app-image-viewer-modal',
    templateUrl: './image-viewer-modal.page.html',
    styleUrls: ['./image-viewer-modal.page.scss'],
})
export class ImageViewerModalPage implements OnInit {

    @Input() public images: string[];
    @Input() public imageIndex: number;

    public image: string;

    constructor(
        private modalController: ModalController,
        private loadingController: LoadingController,
    ) { }

    ngOnInit() {
        this.getImage();
    }

    private async getImage() {
        const imageLoading = await this.loadingController.create({
            message: 'Loading...'
        });
        await imageLoading.present();
        this.image = this.images[this.imageIndex];
        await imageLoading.dismiss();
    }

    public prevImage() {
        if (this.imageIndex === 0) return;
        this.imageIndex = this.imageIndex - 1;
        this.getImage();
    }

    public nextImage() {
        if (this.imageIndex === (this.images.length - 1)) return;
        this.imageIndex = this.imageIndex + 1;
        this.getImage();
    }

    public async closeModal() {
        await this.modalController.dismiss();
    }
}
