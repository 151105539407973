import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ConfigService } from './config.service';

import { Observable, throwError } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';

import { IAsset, IAssetFormConfig, IFormConfig, IUser } from '../models/types';

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    private TIMEOUT = 15000;

    constructor(
        private configService: ConfigService,
        private httpClient: HttpClient
    ) { }

    public convertTemplate(templateId: string): Promise<IFormConfig> {
        const url = this.configService.restApiUrl + '/client/templates/' + templateId + '/convert';
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: 'Bearer ' + this.configService.sessionId,
                'Content-Type': 'application/json'
            })
        };
        return this.httpClient.post(url, {}, httpOptions).pipe(timeout(this.TIMEOUT), map(response => response as any), catchError(e => this.handleError(e))).toPromise();
    }

    public getLatestAssetFormConfig(assetFormConfigId: string): Promise<IAssetFormConfig> {
        const url = this.configService.restApiUrl + '/client/assets/latest_form_config/' + assetFormConfigId;
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: 'Bearer ' + this.configService.sessionId,
                'Content-Type': 'application/json'
            })
        };
        return this.httpClient.get(url, httpOptions).pipe(timeout(this.TIMEOUT), map(response => response as any), catchError(e => this.handleError(e))).toPromise();
    }

    public getProjectFormConfig(): Promise<IAssetFormConfig[]> {
        const url = this.configService.restApiUrl + '/client/asset_templates/form_configs/project';
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: 'Bearer ' + this.configService.sessionId,
                'Content-Type': 'application/json'
            })
        };
        return this.httpClient.get(url, httpOptions).pipe(timeout(this.TIMEOUT), map(response => response as any), catchError(e => this.handleError(e))).toPromise();
    }

    public getUserFormConfig(): Promise<IAssetFormConfig> {
        const url = this.configService.restApiUrl + '/client/asset_templates/form_configs/user';
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: 'Bearer ' + this.configService.sessionId,
                'Content-Type': 'application/json'
            })
        };
        return this.httpClient.get(url, httpOptions).pipe(timeout(this.TIMEOUT), map(response => response as any), catchError(e => this.handleError(e))).toPromise();
    }

    public getAssetsByAssetType(assetFormConfigId: string, status: string, limit?: number, offset?: number, searchQuery?: string): Promise<any[]> {
        const url = this.configService.restApiUrl + '/client/assets/by_type/' + assetFormConfigId;
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: 'Bearer ' + this.configService.sessionId,
                'Content-Type': 'application/json'
            })
        };

        const body: any = { status };
        if (limit) {
            body.limit = limit;
        }
        if (offset) {
            body.offset = offset;
        }
        if (searchQuery) {
            body.search_query = searchQuery;
        }

        return this.httpClient.post(url, body, httpOptions).pipe(timeout(this.TIMEOUT), map(response => response as any), catchError(e => this.handleError(e))).toPromise();
    }

    public getAssetsByIdentifier(identifierType: string, identifierValue: string, assetFormConfigId: string = 'any'): Promise<any[]> {
        const url = this.configService.restApiUrl + '/client/assets/by_identifier/' + assetFormConfigId;
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: 'Bearer ' + this.configService.sessionId,
                'Content-Type': 'application/json'
            })
        };

        const body: any = {
            identifier_type: identifierType,
            identifier_value: identifierValue
        };

        return this.httpClient.post(url, body, httpOptions).pipe(timeout(this.TIMEOUT), map(response => response as any), catchError(e => this.handleError(e))).toPromise();
    }

    public getAsset(assetId: string): Promise<IAsset> {
        const url = this.configService.restApiUrl + '/client/assets/asset/' + assetId;
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: 'Bearer ' + this.configService.sessionId,
                'Content-Type': 'application/json'
            })
        };
        return this.httpClient.get(url, httpOptions).pipe(timeout(this.TIMEOUT), map(response => response as any), catchError(e => this.handleError(e))).toPromise();
    }

    public getUserSignature(): Promise<any> {
        const url = this.configService.restApiUrl + '/client/users/signature/by-session';
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: 'Bearer ' + this.configService.sessionId,
                'Content-Type': 'application/json'
            })
        };
        return this.httpClient.get(url, httpOptions).pipe(timeout(this.TIMEOUT), map(response => response as any), catchError(e => this.handleError(e))).toPromise();
    }

    public getLocalFile<T>(fileName: string, responseType: any = 'json'): Promise<T> {
        return new Promise((resolve, reject) => {
            this.httpClient.get('/assets/' + fileName, { responseType }).pipe(timeout(5000))
                .subscribe((response: any) => {
                    resolve(response);
                }, (error) => {
                    reject(error);
                });
        });
    }

    private handleError(error: any): Observable<Error> {
        if (error.status === 0) {
            return throwError(() => new Error('Cannot connect to the server at present. Please try again later'));
        } else if (error.name === 'TimeoutError') {
            return throwError(() => new Error('Unable to connect to server. Please check your Wi-Fi/Mobile Data settings and try again.'));
        } else {
            throw error.error;
        }
    }
}
