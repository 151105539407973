import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { AlertController, ModalController, ToastController } from '@ionic/angular';

import { AssetInformationModalPage } from '@modals/asset-information-modal/asset-information-modal.page';
import { ProjectSelectModalPage } from '../project-select-modal/project-select-modal.page';

import { ApiService } from '@services/api.service';
import { AssetInformationModalService } from '@modals/asset-information-modal/asset-information-modal.service';
import { AuditService } from '@services/audit.service';
import { FormlyService } from '@services/formly.service';

import { IAsset, IScannedAsset, ITaFormlyTemplateOptions } from '@models/types';

@Component({
    selector: 'app-single-project-select',
    templateUrl: './single-project-select.html',
    styleUrls: ['./single-project-select.scss'],
})
export class SingleProjectSelectComponent implements OnInit {

    @Input() scannedAsset: IScannedAsset = null;
    @Input() props: ITaFormlyTemplateOptions;
    @Input() key: string | number | string[];
    @Output() projectsEvent: EventEmitter<IScannedAsset[]> = new EventEmitter();

    public assetLabel = '';
    private title = '';

    public isNFCEnabled = true;
    public isNFCSupported = true;

    private latestAssetFormConfigId: string;
    private identifierType: 'asset_identifier' | 'rfid_tag' | 'qr_code' | 'uhf_rfid_tag';

    constructor(
        private alertController: AlertController,
        private apiService: ApiService,
        private assetInformationModalService: AssetInformationModalService,
        private auditService: AuditService,
        private changeDetectorRef: ChangeDetectorRef,
        private formlyService: FormlyService,
        private modalController: ModalController,
        private toastController: ToastController,
    ) { }

    async ngOnInit() {
        if (!('selectEnabled' in this.props)) {
            this.props['selectEnabled'] = true;
        }
        if (!('inputEnabled' in this.props)) {
            this.props['inputEnabled'] = false;
        }
        await this.getProjectAssetFormConfig();
    }

    private async getProjectAssetFormConfig() {
        const assetFormConfig = (await this.apiService.getProjectFormConfig())[0];
        this.latestAssetFormConfigId = assetFormConfig.id;
        this.assetLabel = assetFormConfig.sections[0]?.questions[0]?.fields[0]?.templateOptions?.label;
        this.title = assetFormConfig.title;
        this.changeDetectorRef.detectChanges();
    }

    public async presentProjectSelectModal() {
        const projectSelectModal = await this.modalController.create({
            component: ProjectSelectModalPage,
            componentProps: {
                multi: this.props.multi,
                currentlySelectedProjects: this.scannedAsset ? [this.scannedAsset.asset_id] : [],
                projectFormConfigId: this.latestAssetFormConfigId
            },
            canDismiss: true
        });
        await projectSelectModal.present();

        const { data } = await projectSelectModal.onWillDismiss();
        if (data) {
            this.scannedAsset = data[0];
            const asset = await this.apiService.getAsset(this.scannedAsset.asset_id);
            if (this.formlyService.getFormConfig().api === 'v1.2') {
                this.auditService.setAnswersOnPrePopulateQuestions(this.key as string, asset);
            }
            this.saveAsset();
        }
    }

    public async presentInputTextPrompt(inputValue?: string) {
        const METHOD = 'presentInputTextPrompt';
        // console.log(TAG, METHOD, 'inputValue=', inputValue);
        this.identifierType = 'asset_identifier';
        const prompt = await this.alertController.create({
            header: this.title,
            inputs: [
                {
                    name: 'asset_identifier',
                    placeholder: 'Enter ' + this.assetLabel,
                    value: inputValue || ''
                },
            ],
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel'
                },
                {
                    text: 'Submit',
                    handler: async (data) => {
                        if (data && data.asset_identifier) {
                            this.processAssetIdentifier(data.asset_identifier);
                        } else {
                            const toast = await this.toastController.create({ header: 'Value cannot be empty', color: 'danger', position: 'top', duration: 2000 })
                            toast.present();
                            return false;
                        }
                    }
                }
            ],
            backdropDismiss: false
        });
        await prompt.present();
    }

    public async scanQrCode(inputValue?: string) {
        this.identifierType = 'qr_code';
        const prompt = await this.alertController.create({
            header: this.title,
            inputs: [
                {
                    name: 'qr_code',
                    placeholder: 'Enter QR Code',
                    value: inputValue || ''
                },
            ],
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel'
                },
                {
                    text: 'Submit',
                    handler: async (data) => {
                        if (data && data.qr_code) {
                            this.processAssetIdentifier(data.qr_code);
                        } else {
                            const toast = await this.toastController.create({ header: 'Value cannot be empty', color: 'danger', position: 'top', duration: 2000, })
                            toast.present();
                            return false;
                        }
                    }
                }
            ],
            backdropDismiss: false
        });
        await prompt.present();
    }

    public async startScanRfidTag(inputValue?: string) {
        this.identifierType = 'rfid_tag';
        const prompt = await this.alertController.create({
            header: this.title,
            inputs: [
                {
                    name: 'rfid_tag',
                    placeholder: 'Enter RFID Tag',
                    value: inputValue || ''
                },
            ],
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel'
                },
                {
                    text: 'Submit',
                    handler: async (data) => {
                        if (data && data.rfid_tag) {
                            this.processAssetIdentifier(data.rfid_tag);
                        } else {
                            const toast = await this.toastController.create({ header: 'Value cannot be empty', color: 'danger', position: 'top', duration: 2000, })
                            toast.present();
                            return false;
                        }
                    }
                }
            ],
            backdropDismiss: false
        });
        await prompt.present();
    }

    public async presentUhfRfidInputTextPrompt(inputValue?: string) {
        this.identifierType = 'uhf_rfid_tag';
        const prompt = await this.alertController.create({
            header: 'Scan UHF RFID Tag',
            inputs: [
                {
                    name: 'uhf_rfid_tag',
                    placeholder: 'UHF RFID Tag',
                    value: inputValue || ''
                },
            ],
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel'
                },
                {
                    text: 'Submit',
                    handler: async (data) => {
                        if (data && data.uhf_rfid_tag) {
                            this.processAssetIdentifier(data.uhf_rfid_tag);
                        } else {
                            const toast = await this.toastController.create({ header: 'Value cannot be empty', color: 'danger', position: 'top', duration: 2000, })
                            toast.present();
                            return false;
                        }
                    }
                }
            ],
            backdropDismiss: false
        });
        await prompt.present();
    }

    public async presentAssetInformationModal(assetId: string) {
        const asset = await this.apiService.getAsset(assetId);
        const assetInformationModal = await this.modalController.create({
            component: AssetInformationModalPage,
            componentProps: {
                assetId,
                latestAssetFormConfigId: asset.asset_form_config_id,
                asset,
                multi: this.props.multi,
                type: 'view'
            },
            canDismiss: true
        });

        this.assetInformationModalService.pageType = 'modal';
        await assetInformationModal.present();
    }

    public async presentDeleteAlert(assetValue: string) {
        const deleteAssetAlert = await this.alertController.create({
            header: assetValue,
            message: 'Do you want to remove this project?',
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel'
                },
                {
                    text: 'Delete',
                    handler: () => {
                        this.scannedAsset = null;
                        this.saveAsset();
                    }
                }
            ],
            backdropDismiss: false
        });
        await deleteAssetAlert.present();
    }

    private async processAssetIdentifier(identifierValue: string) {
        const assets: IAsset[] = await this.apiService.getAssetsByIdentifier(this.identifierType, identifierValue, this.latestAssetFormConfigId);
        if (assets.length === 0) {
            this.presentNoAssetFoundAlert(identifierValue);
        } else {
            const asset = assets[0];
            const { id } = asset;
            await this.confirmAsset(id, asset);
        }
    }

    private async confirmAsset(assetId: string, asset: IAsset) {
        const assetIdentifier: any = asset.sections[0].questions[0];
        const scannedAsset: IScannedAsset = {
            asset_id: assetId,
            asset_identifier: assetIdentifier.answer,
            label: assetIdentifier.label,
            value: assetIdentifier.label + ': ' + assetIdentifier.answer,
            sections: [{
                questions: asset.sections[0].questions.filter(question => question.showInList && question.answer)
            }]
        };
        this.scannedAsset = scannedAsset;

        if (this.formlyService.getFormConfig().api === 'v1.2') {
            this.auditService.setAnswersOnPrePopulateQuestions(this.key as string, asset);
        }

        this.saveAsset();
    }

    private async presentNoAssetFoundAlert(identifierValue) {
        let message = '';
        if (this.identifierType === 'rfid_tag') {
            message = 'This RFID tag has not been recognised.\nPlease try scanning again.';
        } else if (this.identifierType === 'qr_code') {
            message = 'This QR Code has not been recognised.\nPlease try scanning again.';
        } else if (this.identifierType === 'uhf_rfid_tag') {
            message = 'This UHF RFID Tag has not been recognised.\nPlease try again.';
        } else if (this.identifierType === 'asset_identifier') {
            message = this.assetLabel + ' \'' + identifierValue + '\' has not been recognised.\nPlease try entering the ' + this.assetLabel + ' of another asset of type ' + this.title + '.';
        }

        const noAssetFoundAlert = await this.alertController.create({
            header: 'Cannot find project',
            message,
            buttons: [{
                text: 'Ok',
                handler: () => {
                    if (this.identifierType === 'asset_identifier') {
                        this.presentInputTextPrompt(identifierValue);
                    }
                }
            }]
        });
        await noAssetFoundAlert.present();
    }

    private saveAsset() {
        if (this.scannedAsset) {
            this.projectsEvent.emit([this.scannedAsset]);
        } else {
            this.projectsEvent.emit(null);
        }
    }
}
