import { ChangeDetectionStrategy, Component, HostListener, OnInit } from '@angular/core';

import { FieldType } from '@ngx-formly/core';

import { AuditService } from '@services/audit.service';
import { ConfigService } from '@services/config.service';

@Component({
    selector: 'app-ta-input',
    templateUrl: 'ta-input.html',
    styleUrls: ['ta-input.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaInputComponent extends FieldType implements OnInit {

    public disableClear = false;
    private updatedKey: string;

    constructor(
        private auditService: AuditService,
        private configService: ConfigService
    ) {
        super();
    }

    @HostListener('ionBlur')
    public ionBlur() {
        if (this.formControl.value || !isNaN(parseFloat(this.formControl.value))) {
            if (this.props.showInList) {
                const value = { key: this.updatedKey, label: this.props.label, answer: this.formControl.value, type: this.field.type as string };
                this.auditService.addShowInListValue(value);
            }
        } else {
            if (this.props.showInList) {
                this.auditService.removeShowInListValue(this.updatedKey as string);
            }
        }
    }

    @HostListener('ionChange')
    public ionChange() {
        if (this.formControl.value || !isNaN(parseFloat(this.formControl.value))) {
            this.disableClear = false;
            this.auditService.setLocationAndTimestamp(this.updatedKey);
            if (this.model.audit_type === 'Register Item Checklist') {
                if (this.updatedKey === 'data.section_0.question_1.findings.value') {
                    this.model.register_item_id = this.formControl.value;
                }
            }
        } else {
            this.disableClear = true;
            this.auditService.unsetLocationAndTimestamp(this.updatedKey);
            if (this.model.audit_type === 'Register Item Checklist') {
                if (this.updatedKey === 'data.section_0.question_1.findings.value') {
                    this.model.register_item_id = null;
                }
            }
        }
    }

    ngOnInit() {
        this.updatedKey = this.key as string;
        if (this.field.parent?.key && this.field.parent?.parent.key) {
            this.updatedKey = this.field.parent?.parent.key + '[' + this.field.parent?.key + '].' + this.key;
        }
    }

    public clear() {
        // The formControl.reset triggers an ionChange event with a null formControl.value
        this.formControl.reset(null);
        if (this.model.audit_type === 'Register Item Checklist') {
            if (this.updatedKey === 'data.section_0.question_1.findings.value') {
                this.model.register_item_id = null;
            }
        }
    }

}
