<ion-item lines='none'>
    <ion-button tabIndex="1" class="ion-margin-right-0" slot="end" color="secondary" fill="clear" (click)="clear()"
        [disabled]="disableClear">
        Clear
        <ion-icon slot="end" name="close-circle-outline" class="ion-label-font-xlg"></ion-icon>
    </ion-button>
</ion-item>

<ion-item lines="none">
    <ion-input tabIndex="2" autoFocus="false" *ngIf="props.type !== 'number'; else numberType" class="ion-label-font-sm"
        autocapitalize="true" autocomplete="true" autocorrect="true" spellcheck="true" [type]="props.type || 'text'"
        [formControl]="$any(formControl)" [taFormlyAttributes]="field"
        [placeholder]="props.placeholder || 'Input answer here ...'">
    </ion-input>
    <ng-template #numberType>
        <ion-input tabIndex="2" autoFocus="false" type="number" class="ion-label-font-sm" [inputmode]="props.inputmode"
            [formControl]="$any(formControl)" [taFormlyAttributes]="field"
            [placeholder]="props.placeholder || 'Input number here ...'">
        </ion-input>
    </ng-template>
</ion-item>